import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {CreditNote, CreditNoteReason, Invoice, SupplierInvoiceLine} from '../../../models/financials';
import {TreeNode} from 'primeng/api';
import {Subscription} from 'rxjs';
import * as moment from 'moment';
import {CreditNoteService} from '../../../services/credit-note.service';
import {Entry} from '../fin-supplrinv-headers/fin-supplrinv-headers.component';
import {File} from '../../../models/file';
import {NotificationService} from '../../../services/notification.service';
import {FileType} from '../../../models/enumerations';
import {TaxType} from '../../../models/TariffCode';
import {KeyValue} from '@angular/common';
import {BillOfEntry, ExportBillOfEntry} from '../../../models/billOfEntries';
import {BankingDetails} from '../../../models/company';

@Component({
  selector: 'digi-credit-note',
  templateUrl: './credit-note.component.html',
  styleUrls: ['./credit-note.component.scss']
})
export class CreditNoteComponent implements OnInit, OnChanges {

  invoiceDetails = [];
  creditNoteDetails: TreeNode[] = [];
  creditNote: CreditNote = new CreditNote();
  creditNotes: CreditNote[];
  showCreditNotePrintView: boolean;
  filteredReasons: CreditNoteReason[];

  @Input() invoices: Invoice[];
  @Input() invoice: Invoice;
  @Input() file: File;
  @Input() fileType: FileType;
  @Input() logo: string;
  @Input() transportMethod: string;
  @Input() selectedBillOfEntry: BillOfEntry|ExportBillOfEntry;
  @Input() vat: number;

  creditNotesSubscription: Subscription;
  creditNoteSubscription: Subscription;

  creditNoteCols: any[];
  @Input() bankingDetails: BankingDetails[];

  constructor(
    private creditNoteService: CreditNoteService,
    private messageService: NotificationService,
  ) { }

  ngOnInit() {
    this.creditNoteCols = [
      {field: 'invoiceNo', header: 'Invoice No'},
      {field: 'creditNoteNo', header: 'Credit Note No'},
      {field: 'date', header: 'Invoice Date'}
    ];
  }

  order = (a: KeyValue<number, string>, b: KeyValue<number, string>): number => {
    return 0;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.file.currentValue) {
      this.file = changes.file.currentValue;
      this.getCreditNotes(this.file);
    }

    if (changes.invoices.currentValue) {
      this.invoices = changes.invoices.currentValue;
      this.createTree();
    }

    if (changes.invoice.currentValue) {
      this.invoice = changes.invoice.currentValue;
      if (!this.creditNote.invoiceNo) {
        this.createNewCreditNote();
      }
    }

    if (changes.fileType.currentValue) {
      this.fileType = changes.fileType.currentValue;
    }

    if (changes.logo.currentValue) {
      this.logo = changes.logo.currentValue;
    }

    if (changes.transportMethod.currentValue) {
      this.transportMethod = changes.logo.currentValue;
    }

    if (changes.selectedBillOfEntry.currentValue) {
      this.selectedBillOfEntry = changes.selectedBillOfEntry.currentValue;
    }

    if (changes.vat.currentValue) {
      this.vat = changes.vat.currentValue;
    }
  }

  getRounded(value) {
    if (value) {
      if (typeof value === 'string') {
        return Number(value).toFixed(2);
      } else {
        return value.toFixed(2);
      }
    } else {
      return '0.00';
    }

  }

  private getCreditNotes(file) {
    this.creditNotesSubscription = this.creditNoteService.getCreditNotes(file).subscribe(
      creditNotes => {
        this.creditNotes = creditNotes;
        if (creditNotes && creditNotes.length) {
          this.creditNote = creditNotes[0];
        }

        if (!this.creditNote.invoiceNo) {
          this.createNewCreditNote();
        }
      }
    );
  }

  createTree() {
    this.invoiceDetails = [];
    const invoices = [...this.invoices];
    invoices.forEach(invoice => {
      const invoiceSections = [];
      invoice.lineDetails.forEach(line => {
        let invoiceSectionTree = invoiceSections.find(detail => detail.label === line.invoiceSection.description);
        if (invoiceSectionTree) {
          let invoiceSubSectionTree = invoiceSectionTree.children.find(el => el.label === line.invoiceSubSection);

          if (invoiceSubSectionTree) {
            let chargeTypeTree = invoiceSubSectionTree.children.find(el => el.label === line.chargeType.description);

            if (chargeTypeTree) {
              chargeTypeTree.children.push({
                'label': line.tranType.description,
                'data': Object.assign({}, line),
                'children': []
              });
            } else {
              chargeTypeTree = {
                'label': line.chargeType.description,
                'data': Object.assign({}, line),
                'children': [
                  {
                    'label': line.tranType.description,
                    'data': Object.assign({}, line),
                    'children': []
                  }
                ]
              };

              invoiceSubSectionTree.children.push(chargeTypeTree);
            }
          } else {
            const chargeTypeTree = {
              'label': line.chargeType.description,
              'data': Object.assign({}, line),
              'children': [
                {
                  'label': line.tranType.description,
                  'data': Object.assign({}, line),
                  'children': []
                }
              ]
            };

            invoiceSubSectionTree = {
              'label': line.invoiceSubSection,
              'data': Object.assign({}, line),
              'children': [chargeTypeTree]
            };
            invoiceSectionTree.children.push(invoiceSubSectionTree);
          }

        } else {
          const chargeTypeTree = {
            'label': line.chargeType.description,
            'data': Object.assign({}, line),
            'children': [
              {
                'label': line.tranType.description,
                'data': Object.assign({}, line),
                'children': []
              }
            ]
          };

          const invoiceSubSectionTree = {
            'label': line.invoiceSubSection,
            'data': Object.assign({}, line),
            'children': [chargeTypeTree]
          };

          invoiceSectionTree = {
            'label': line.invoiceSection.description,
            'data': Object.assign({}, line),
            'children': [invoiceSubSectionTree]
          };

          invoiceSections.push(invoiceSectionTree);
        }
      });

      this.invoiceDetails.push({
        'label': `${invoice.invoiceNo} : ${invoice.currency.code}`,
        'children': invoiceSections
      });
    });
  }

  getCreditNoteLines() {
    const entries: Entry[] = [];
    if (this.creditNote.lineDetails) {
      this.creditNote.lineDetails.forEach(el => {
        if (el.invoiceSection) {
          const foundEntry = entries.find(entry => entry.invoiceSection === el.invoiceSection.description);
          if (foundEntry) {
            const foundLine = foundEntry.lines.find(l => l.invoiceSubSection === el.invoiceSubSection);
            // do not add if line already exists (consolidated already)
            if (!foundLine) {
              const consolidatedLine = this.consolidateCreditNoteLines(el.invoiceSubSection, el.taxType.sarsTaxCode);
              foundEntry.lines.push(consolidatedLine);
            }
          } else {
            entries.push({invoiceSection: el.invoiceSection.description, lines: [
              this.consolidateCreditNoteLines(el.invoiceSubSection, el.taxType.sarsTaxCode)]});
          }
        }
      });
    }

    return entries;
  }

  private consolidateCreditNoteLines(invoiceSubSectionDesc: string, code: string) {
    const line: SupplierInvoiceLine = new SupplierInvoiceLine();
    this.creditNote.lineDetails
      .forEach(
        l => {
          if (l.invoiceSubSection === invoiceSubSectionDesc && l.taxType.sarsTaxCode === code) {
            line.invoiceSection = l.invoiceSection;
            line.invoiceSubSection = l.invoiceSubSection;
            line.localAmount = line.localAmount ? Number(line.localAmount) + Number(l.localAmount) : Number(l.localAmount);
            line.localTax = line.localTax ? Number(line.localTax) + Number(l.localTax) : Number(l.localTax);
            line.taxType = l.taxType;
          }
        }
      );
    return line;
  }

  getCreditNoteTotal(lines, value) {
    let sum = 0;
    if (lines) {
      lines.forEach(el => {
        if (value === 'vat') {
          el.localTax ? sum += Number(el.localTax) : sum += 0;
        }

        if (value === 'localAmount') {
          el.localAmount ? sum += Number(el.localAmount) : sum += 0;
        }

      });
    } else {
      if (value === 'totalExclVat' && this.creditNote.lineDetails) {
        this.creditNote.lineDetails.forEach(lineDetail => {
          lineDetail.localAmount ? sum += Number(lineDetail.localAmount) : sum += 0;
        });
      }

      if (value === 'totalVat' && this.creditNote.lineDetails) {
        this.creditNote.lineDetails.forEach(lineDetail => {
          lineDetail.localTax ? sum += Number(lineDetail.localTax) : sum += 0;
        });
      }
    }
    return Number(sum.toFixed(2));
  }

  getCreditNoteTaxSummary() {
    const taxSummaryArr = {S: 0, E: 0, Z: 0, N: 0};
    if (this.creditNote.lineDetails) {
      this.creditNote.lineDetails.forEach(
        el => {
          if (el.taxType) {
            taxSummaryArr[el.taxType.sarsTaxCode] += Number(el.localAmount);
          }
        }
      );
    }
    return taxSummaryArr;
  }

  calculateLocalTax(taxType: TaxType, localAmount: number) {
    if (taxType.code.trim() === 'VAT') {
      return (localAmount * this.vat).toFixed(2);
    } else {
      return 0;
    }
  }

  updateCreditNoteLines() {
    this.creditNote.lineDetails = [...this.creditNoteDetails.filter(el => el.children.length === 0).map(el => el.data)];
  }

  saveCreditNote() {
    if (!this.creditNote.creditNoteDate) {
      this.creditNote.creditNoteDate = moment().format('DD/MM/YYYY');
    }
    this.creditNoteSubscription = this.creditNoteService.saveCreditNote(this.creditNote, this.file).subscribe(
      data => {
        this.messageService.successNotify('Credit Note Saved Successfully');
        this.creditNote = data;
        if (!this.creditNotes.find(el => el.creditNoteNo === this.creditNote.creditNoteNo)) {
          this.creditNotes.push(this.creditNote);
        }
      }
    );
  }

  createNewCreditNote() {
    this.creditNote = Object.assign({}, this.invoice);
    this.creditNote._links = null;
    this.creditNote.id = null;
    this.creditNote.lineDetails = [];
    this.creditNote.capturedAmount = null;
    this.creditNote.reason = new CreditNoteReason();
    this.createTree();
  }

  searchReasons(event) {
    this.creditNoteService.findReasonsByCode(event.query).subscribe(
      (reasons: CreditNoteReason[]) => this.filteredReasons = reasons
    );
  }

  selectReason(value) {
    this.creditNote.reason = value;
  }

  print() {
    window.print();
  }

  calculateLocalAmountSumOfChildren(children: any[]) {
    let sum = 0;
    if (children) {
      sum = children.reduce((accumulator, currentValue) => accumulator + Number(currentValue.data.localAmount), 0);
    }
    return this.getRounded(sum);
  }

  calculateTaxSumOfChildren(children: any[]) {
    let sum = 0;
    if (children) {
      sum = children.reduce((accumulator, currentValue) => accumulator + Number(currentValue.data.localTax), 0);
    }
    return this.getRounded(sum);
  }
}
