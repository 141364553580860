<p-tabView orientation="left" styleClass="stepMenu">
  <p-tabPanel header="Create Inv." [selected]="true">
    <ng-template pTemplate="content">
      <div class="ui-g-12 ui-g-nopad">
        <div class="ui-g-12 ui-md-12">
          <div class="ui-g-6 ui-md-6">
            <digi-clearing-file-search *ngIf="fileType === 'imports'"></digi-clearing-file-search>
            <digi-export-file-search *ngIf="fileType === 'exports'"></digi-export-file-search>
          </div>
          <p-toolbar class="toolbar">
            <div class="ui-toolbar-group-right">
              <button *ngIf="invoice.confirmed" pButton type="button" label="Print"
                      icon="fa fa-print"
                      (click)="print()"></button>
              <button *ngIf="!invoice.confirmed" pButton type="button"
                      [label]="showPrintView?'Edit':'Print Preview'"
                      [icon]="showPrintView?'fa fa-pencil':'fa fa-print'"
                      (click)="showPrintView=!showPrintView"></button>
              <button pButton type="button" label="Confirm" icon="fa fa-check" (click)="confirmInvoice()"
                      [disabled]="invoice.confirmed || (invoice.localAmount - (getCaptured()) < 0 || getCaptured()===0)">
              </button>
              <button pButton type="button" label="Save" icon="fa fa-save" (click)="saveInvoice()"
                      [disabled]="invoice.confirmed || getCaptured()===0"></button>
              <button pButton type="button" label="New" icon="fa fa-file"
                      (click)="createNewInvoice();showPrintView=false"></button>
            </div>
          </p-toolbar>
        </div>
        <div class="ui-g-12 ui-md-12">
          <p-table [columns]="cols" [value]="invoices" selectionMode="single"
                   columnResizeMode="expand"
                   [(selection)]="invoice" [paginator]="true" [rows]="5">
            <ng-template pTemplate="header" let-columns>
              <tr>
                <th *ngFor="let col of columns">{{col.header}}</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-selection let-rowIndex="rowIndex">
              <tr [pSelectableRow]="selection" [pSelectableRowIndex]="rowIndex" [style]="{'height':'38px'}">
                <td>{{selection.invoiceNo}}</td>
                <td>{{selection.invoiceDate}}</td>
              </tr>
            </ng-template>
          </p-table>

        </div>
        <div class="ui-g-12 ui-g-nopad ui-md-12 border-thick print">
          <div class="ui-g-9 ui-md-9">
            <div class="ui-g-12 ui-md-12">
              <div class="ui-g-12 ui-md-12">
                <div class="ui-g-12 ui-md-12">
                  <span *ngIf="showPrintView || invoice.confirmed" class="company-name">{{invoice.companyName}}</span>
                  <div *ngIf="!showPrintView && !invoice.confirmed" class="ui-g-12 ui-md-12">
                    <div class="ui-inputgroup">
                        <span class="md-inputfield">
                          <input class="ui-md-6 ui-g-6" type="text" pInputText
                                 [(ngModel)]="invoice.companyName">
                          <label>Company Name</label>
                        </span>
                    </div>
                  </div>
                </div>
                <div class="ui-g-3 ui-md-3 ui-fluid">
                  <span *ngIf="showPrintView || invoice.confirmed">Vat No: {{invoice.companyVatNo}}</span>
                  <div *ngIf="!showPrintView && !invoice.confirmed" class="ui-g-12 ui-md-12">
                    <div class="ui-inputgroup">
                        <span class="md-inputfield">
                          <input type="text" pInputText
                                 [(ngModel)]="invoice.companyVatNo">
                          <label>VAT No</label>
                        </span>
                    </div>
                  </div>
                </div>
                <div class="ui-g-3 ui-md-3 ui-fluid">
                  <span *ngIf="showPrintView || invoice.confirmed">Reg: {{invoice.companyReg}}</span>
                  <div *ngIf="!showPrintView && !invoice.confirmed" class="ui-g-12 ui-md-12">
                    <div class="ui-inputgroup">
                        <span class="md-inputfield">
                          <input type="text" pInputText
                                 [(ngModel)]="invoice.companyReg">
                          <label>Reg</label>
                        </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="ui-g-12 ui-md-12">
                <div *ngIf="invoice.companyPhysicalAddress" class="ui-g-3 ui-md-3 ui-fluid">
                  <span *ngIf="showPrintView || invoice.confirmed">Physical Address:<br>
                    {{invoicePhysicalAddress?.line1}}<br>
                    {{invoicePhysicalAddress?.line2}}<br>
                    {{invoicePhysicalAddress?.line3}}<br>
                    {{invoicePhysicalAddress?.line4}}<br>
                    {{invoicePhysicalAddress?.zipCode}}</span>
                  <div *ngIf="!showPrintView && !invoice.confirmed" class="ui-g-12 ui-md-12">
                    <div class="ui-inputgroup">
                        <span class="md-inputfield">
                          <input type="text" pInputText
                                 [(ngModel)]="invoicePhysicalAddress.line1">
                          <label>Physical Address</label>
                        </span>
                    </div>
                    <div class="ui-inputgroup">
                        <span class="md-inputfield">
                          <input type="text" pInputText
                                 [(ngModel)]="invoicePhysicalAddress.line2">
                        </span>
                    </div>
                    <div class="ui-inputgroup">
                        <span class="md-inputfield">
                          <input type="text" pInputText
                                 [(ngModel)]="invoicePhysicalAddress.line3">
                        </span>
                    </div>
                    <div class="ui-inputgroup">
                        <span class="md-inputfield">
                          <input type="text" pInputText
                                 [(ngModel)]="invoicePhysicalAddress.line4">
                        </span>
                    </div>
                    <div class="ui-inputgroup">
                        <span class="md-inputfield">
                          <input type="text" pInputText
                                 [(ngModel)]="invoicePhysicalAddress.zipCode">
                        </span>
                    </div>
                  </div>
                </div>
                <div *ngIf="invoice.companyPostalAddress" class="ui-g-3 ui-md-3 ui-fluid">
                  <span *ngIf="showPrintView || invoice.confirmed">Postal Address:<br>
                    {{invoicePostalAddress?.line1}}<br>
                    {{invoicePostalAddress?.line2}}<br>
                    {{invoicePostalAddress?.line3}}<br>
                    {{invoicePostalAddress?.line4}}<br>
                    {{invoicePostalAddress?.zipCode}}</span>
                  <div *ngIf="!showPrintView && !invoice.confirmed" class="ui-g-12 ui-md-12">
                    <div class="ui-inputgroup">
                        <span class="md-inputfield">
                          <input type="text" pInputText
                                 [(ngModel)]="invoicePostalAddress.line1">
                          <label>Postal Address</label>
                        </span>
                    </div>
                    <div class="ui-inputgroup">
                        <span class="md-inputfield">
                          <input type="text" pInputText
                                 [(ngModel)]="invoicePostalAddress.line2">
                        </span>
                    </div>
                    <div class="ui-inputgroup">
                        <span class="md-inputfield">
                          <input type="text" pInputText
                                 [(ngModel)]="invoicePostalAddress.line3">
                        </span>
                    </div>
                    <div class="ui-inputgroup">
                        <span class="md-inputfield">
                          <input type="text" pInputText
                                 [(ngModel)]="invoicePostalAddress.line4">
                        </span>
                    </div>
                    <div class="ui-inputgroup">
                        <span class="md-inputfield">
                          <input type="text" pInputText
                                 [(ngModel)]="invoicePostalAddress.zipCode">
                        </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="ui-g-12 ui-md-12">
                <div class="ui-g-3 ui-md-3 ui-fluid">
                  <span *ngIf="showPrintView || invoice.confirmed">Tel: {{invoice.companyTel}}</span>
                  <div *ngIf="!showPrintView && !invoice.confirmed" class="ui-g-12 ui-md-12">
                    <div class="ui-inputgroup">
                        <span class="md-inputfield">
                          <input type="text" pInputText
                                 [(ngModel)]="invoice.companyTel">
                          <label>Tel</label>
                        </span>
                    </div>
                  </div>
                </div>
                <div class="ui-g-3 ui-md-3 ui-fluid">
                  <span *ngIf="showPrintView || invoice.confirmed">Fax: {{invoice.companyFax}}</span>
                  <div *ngIf="!showPrintView && !invoice.confirmed" class="ui-g-12 ui-md-12">
                    <div class="ui-inputgroup">
                        <span class="md-inputfield">
                          <input type="text" pInputText
                                 [(ngModel)]="invoice.companyFax">
                          <label>Fax</label>
                        </span>
                    </div>
                  </div>
                </div>
                <div class="ui-g-6 ui-md-6 ui-fluid">
                  <span *ngIf="showPrintView || invoice.confirmed">Email: {{invoice.companyEmail}}</span>
                  <div *ngIf="!showPrintView && !invoice.confirmed" class="ui-g-12 ui-md-12">
                    <div class="ui-inputgroup">
                        <span class="md-inputfield">
                          <input type="text" pInputText
                                 [(ngModel)]="invoice.companyEmail">
                          <label>Email</label>
                        </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="ui-g-3 ui-md-3">
            <div class="ui-g-12 ui-md-12 company-logo" [style.background-image]="logo"></div>
          </div>
          <hr class="ui-g-12 ui-md-12 ui-g-nopad border-thin">
          <div class="ui-g-5 ui-md-5">
            <div class="ui-g-8 ui-md-6">
              <span *ngIf="!invoice.copy" class="document-title">Tax Invoice</span>
              <span *ngIf="invoice.copy" class="document-title">Copy Tax Invoice</span>
            </div>
            <div class="ui-g-4 ui-md-4 no-print">
              <p-checkbox [binary]="true" label="Is Copy?" [(ngModel)]="invoice.copy"></p-checkbox>
            </div>
            <div *ngIf="file.debtor" class="ui-g-12 ui-md-12">
              To:
              <div class="ui-g-12 ui-md-12">
                {{file.debtor.name | uppercase}}
              </div>
              <div *ngIf="file.debtor.address && file.debtor.address.line1"
                   class="ui-g-12 ui-md-12 no-padding-top-bottom">
                {{file.debtor.address.line1 | uppercase}}
              </div>
              <div *ngIf="file.debtor.address && file.debtor.address.line2"
                   class="ui-g-12 ui-md-12 no-padding-top-bottom">
                {{file.debtor.address.line2 | uppercase}}
              </div>
              <div *ngIf="file.debtor.address && file.debtor.address.line3"
                   class="ui-g-12 ui-md-12 no-padding-top-bottom">
                {{file.debtor.address.line3 | uppercase}}
              </div>
              <div *ngIf="file.debtor.address && file.debtor.address.line4"
                   class="ui-g-12 ui-md-12 no-padding-top-bottom">
                {{file.debtor.address.line4 | uppercase}}
              </div>
              <div *ngIf="file.debtor.address && file.debtor.address.zipCode" class="ui-g-12 ui-md-12">
                {{file.debtor.address.zipCode}}
              </div>
            </div>
          </div>
          <div class="ui-g-7 ui-md-7">
            <div class="ui-g-6 ui-md-6">
              <div class="ui-g-12 ui-md-12 border-left border-right border-top border-bottom">
                <span *ngIf="showPrintView || invoice.confirmed">Date: {{invoice.invoiceDate}}</span>
                <div *ngIf="!showPrintView && !invoice.confirmed" class="ui-g-12 ui-md-12">
                  <div class="ui-inputgroup">
                        <span class="md-inputfield">
                          Date:
                          <p-inputMask id="invoiceDate" mask="99/99/9999" slotChar="dd/mm/yyyy"
                                       [(ngModel)]="invoice.invoiceDate">
                          </p-inputMask>
                        </span>
                  </div>
                </div>
              </div>
              <div class="ui-g-12 ui-md-12 border-left border-right border-bottom">
                Client Account No.: {{ file.debtor.code }}
              </div>
              <div class="ui-g-12 ui-md-12 border-left border-right border-bottom">
                Client VAT No.: {{file.debtor.vatNo}}
              </div>
              <div class="ui-g-12 ui-md-12 border-left border-right border-bottom">
                Reference: {{ file.clearingFileNumber }}
              </div>
            </div>
            <div class="ui-g-6 ui-md-6">
              <div class="ui-g-12 ui-md-12 border-left border-right border-top border-bottom">
                <span *ngIf="showPrintView || invoice.confirmed">Page: {{invoice.page}}</span>
                <div *ngIf="!showPrintView && !invoice.confirmed" class="ui-g-12 ui-md-12">
                  <div class="ui-inputgroup">
                    <span class="md-inputfield">
                      Page
                      <input type="text" pInputText
                             [(ngModel)]="invoice.page" placeholder="(e.g. 1 of 2)">
                    </span>
                  </div>
                </div>
              </div>
              <div class="ui-g-12 ui-md-12 border-left border-right border-bottom">
                <span *ngIf="showPrintView || invoice.confirmed">Client Reference: {{file.reference.referenceFileNo}}</span>
                <div *ngIf="!showPrintView && !invoice.confirmed" class="ui-g-12 ui-md-12">
                  <div class="ui-inputgroup">
                        <span class="md-inputfield">
                          Client Reference
                          <input type="text" pInputText
                                 [(ngModel)]="file.reference.referenceFileNo">
                        </span>
                  </div>
                </div>
              </div>
              <div class="ui-g-12 ui-md-12 border-left border-right border-bottom">
                Invoice No.: {{ invoice.invoiceNo}}
              </div>
              <div class="ui-g-12 ui-md-12 border-left border-right border-bottom">
                <span *ngIf="showPrintView || invoice.confirmed">Alternate Reference: {{invoice.altReference}}</span>
                <div *ngIf="!showPrintView && !invoice.confirmed" class="ui-g-12 ui-md-12">
                  <div class="ui-inputgroup">
                        <span class="md-inputfield">
                          Alternate Reference
                          <input type="text" pInputText
                                 [(ngModel)]="invoice.altReference">
                        </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="ui-g-12 ui-md-12">
              <div class="ui-g-6 ui-md-6">
                THIS INVOICE IS PAYABLE ON OR BEFORE
              </div>
              <div class="ui-g-6 ui-md-6">
                <div class="ui-g-12 ui-md-12 border-thin">
                  <span *ngIf="showPrintView || invoice.confirmed">{{invoice.invoiceDueDate}}</span>
                  <div *ngIf="!showPrintView && !invoice.confirmed" class="ui-g-12 ui-md-12">
                    <div class="ui-inputgroup">
                        <span class="md-inputfield">
                          <p-inputMask id="invoiceDueDate" mask="99/99/9999" slotChar="dd/mm/yyyy"
                                       [(ngModel)]="invoice.invoiceDueDate">
                          </p-inputMask>
                        </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="ui-g-12 ui-md-12 no-padding-top-bottom">
            <div class="border-thin ui-g-12 ui-md-12">
              <div class="ui-g-4 ui-md-4">
                <div class="ui-g-12 ui-md-12 no-padding-top-bottom">
                  <div class="ui-g-12 ui-md-12 no-padding-top-bottom">
                    Master No : {{file?.transportDocument?.airline?.prefix ? file.transportDocument.airline.prefix + ' -' : ''}} {{file?.transportDocument?.mawbNo}}
                  </div>
                </div>
                <div class="ui-g-12 ui-md-12 no-padding-top-bottom">
                  <div class="ui-g-12 ui-md-12 no-padding-top-bottom">
                    House No : {{file?.transportDocument?.hawbNo}}
                  </div>
                </div>
                <div class="ui-g-12 ui-md-12 no-padding-top-bottom">
                  <div class="ui-g-12 ui-md-12 no-padding-top-bottom" *ngIf="fileType==='imports'">
                    Supplier : {{file?.transportDocument?.supplierInvoices[0]?.supplier?.name}}
                  </div>
                  <div class="ui-g-12 ui-md-12 no-padding-top-bottom" *ngIf="fileType==='exports'">
                    Consignee : {{file?.transportDocument?.supplierInvoices[0]?.supplier.name}}
                  </div>
                </div>
                <div class="ui-g-12 ui-md-12 no-padding-top-bottom">
                  <div class="ui-g-12 ui-md-12 no-padding-top-bottom">
                    Container No : <span
                    *ngIf="file && file.transportDocument && file.transportDocument.containers && file.transportDocument.containers[0]">{{file.transportDocument.containers[0].containerNo}}</span>
                  </div>
                </div>
              </div>
              <div class="ui-g-4 ui-md-4">
                <div class="ui-g-12 ui-md-12 no-padding-top-bottom">
                  <div class="ui-g-12 ui-md-12 no-padding-top-bottom">
                    Port of Disch : <span
                    *ngIf="file && file.transportDocument && file.transportDocument.destinationPort">{{file.transportDocument.destinationPort.name}}</span>
                  </div>
                </div>
                <div class="ui-g-12 ui-md-12 no-padding-top-bottom">
                  <div *ngIf="transportMethod==='AIR'" class="ui-g-12 ui-md-12 no-padding-top-bottom">
                    Flight No : <span>{{file?.transportDocument?.flight}}</span>
                  </div>
                  <div *ngIf="transportMethod==='SEA'" class="ui-g-12 ui-md-12 no-padding-top-bottom">
                    Voyage : <span>{{file?.transportDocument?.voyageDetails?.voyageNo}}</span>
                  </div>
                </div>
                <div *ngIf="transportMethod==='SEA'" class="ui-g-12 ui-md-12 no-padding-top-bottom">
                  <div class="ui-g-12 ui-md-12 no-padding-top-bottom">
                    Vessel Name :
                    <span *ngIf="file && file.transportDocument && file.transportDocument.voyageDetails">
                      {{file.transportDocument.voyageDetails.customsRadioCallSign.vesselName}}</span>
                  </div>
                </div>
                <div class="ui-g-12 ui-md-12 no-padding-top-bottom">
                  <div class="ui-g-12 ui-md-12 no-padding-top-bottom">
                    BOE No. : {{ selectedBillOfEntry?.mrn }}
                  </div>
                </div>
              </div>
              <div class="ui-g-4 ui-md-4">
                <div class="ui-g-12 ui-md-12 no-padding-top-bottom">
                  <div class="ui-g-8 ui-md-8 no-padding-top-bottom">
                    Port of Load : {{file?.transportDocument?.departurePort?.name}}
                  </div>
                </div>
                <div class="ui-g-12 ui-md-12 no-padding-top-bottom">
                  <div class="ui-g-8 ui-md-8 no-padding-top-bottom">
                    ETA/ETD : {{file?.transportDocument?.arrivalDate}}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="!showPrintView && !invoice.confirmed" class="ui-g-12 ui-md-12 no-print">
            <div class="ui-g-10 ui-md-10 ui-fluid">
              <p-panel header="Values">
                <div class="ui-g form-group">
                  <div class="ui-g-3 ui-md-3">
                    <digi-currency [currency]="invoice.currency"
                                   (onSelect)="$event.code==='ZAR'?invoice.exchangeRate=1:invoice.currency.exchangeRate;invoice.currency=$event"></digi-currency>
                  </div>
                  <div class="ui-g-3 ui-md-3">
                    <div class="ui-g-12 ui-md-12">
                      <span class="md-inputfield">
                          <p-autoComplete [(ngModel)]="invoice.taxType"
                                          [dropdown]="true"
                                          field="label"
                                          [suggestions]="filteredTaxTypes"
                                          (completeMethod)="searchTaxTypeCode($event)"
                                          [autoHighlight]="true"
                                          (onSelect)="invoice.taxType=$event"
                                          minLength="0"
                                          required=true>
                        <ng-template let-option pTemplate="item">
                          <div>{{option.label}}</div>
                        </ng-template>
                      </p-autoComplete>
                          <label>Default Tax Type</label>
                        </span>
                    </div>
                  </div>
                  <div class="ui-g-2 ui-md-2">
                    <div class="ui-g-12 ui-md-12">
                      <div class="ui-inputgroup">
                        <span class="md-inputfield">
                          <input type="number" pInputText
                                 [(ngModel)]="invoice.exchangeRate">
                          <label>Exchange Rate</label>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="ui-g-2 ui-md-2">
                    <div class="ui-g-12 ui-md-12">
                      <div class="ui-inputgroup">
                        <span class="md-inputfield">
                          <input type="number" pInputText
                                 [(ngModel)]="invoice.localAmount">
                          <label>Local Amount</label>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="ui-g-2 ui-md-2">
                    <div class="ui-g-12 ui-md-12">
                      <div class="ui-inputgroup">
                        <span class="md-inputfield">
                          <input type="number" pInputText
                                 [(ngModel)]="invoice.foreignAmount">
                          <label>Foreign Amount</label>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </p-panel>
            </div>
            <digi-fin-invdetails-total
              [capturedAmount]="getCaptured()"
              [localAmount]="invoice.localAmount"
            ></digi-fin-invdetails-total>
          </div>
          <div class="ui-g-12 ui-md-12">
            <div *ngIf="showPrintView" class="ui-g-12 ui-md-12 border-thick no-padding-top-bottom">
              <div class="ui-g-6 ui-md-6"></div>
              <div class="ui-g-2 ui-md-2">VAT</div>
              <div class="ui-g-2 ui-md-2">VAT code</div>
              <div class="ui-g-2 ui-md-2">Invoice Amount (Excl. VAT)</div>
            </div>
            <button *ngIf="!showPrintView && !invoice.confirmed" pButton type="button" icon="fa fa-plus"
                    (click)="addNewLine()" label="Add Line"></button>

            <ng-container *ngIf="!showPrintView && !invoice.confirmed">
              <p-table [value]="invoice.lineDetails" editMode="row" #templateRef>
                <ng-template pTemplate="header">
                  <tr>
                    <th style="width: 14%">Charge Type</th>
                    <th style="width:12%">Tran. Type</th>
                    <th style="width:7%">Tax Type</th>
                    <th style="width:10%">Local Amount</th>
                    <th style="width:10%">Local Tax</th>
                    <th style="width:7%">Foreign Amount</th>
                    <th style="width:7%">Foreign Tax</th>
                    <th style="width:16%">Invoice Section</th>
                    <th style="width:16%">Invoice Sub-section</th>
                    <th style="width:4em"></th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-ri="rowIndex">
                  <tr #row>
                    <td>
                      <p-autoComplete [inputStyle]="{'width':'85%','padding':'0'}"
                                      [(ngModel)]="rowData.chargeType"
                                      field="description"
                                      [suggestions]="filteredChargeTypes"
                                      (completeMethod)="searchChargeType($event)"
                                      [autoHighlight]="true"
                                      [dropdown]="true"
                                      minLength="2"
                                      required=true
                                      class="ui-fluid"
                                      [pTooltip]="rowData.chargeType?.description"
                                      tooltipPosition="bottom"
                                      forceSelection=true>
                        <ng-template let-option pTemplate="item">
                          <div>{{option.label}}</div>
                        </ng-template>
                      </p-autoComplete>
                    </td>
                    <td>
                      <p-autoComplete [inputStyle]="{'width':'85%','padding':'0'}"
                                      [(ngModel)]="rowData.tranType"
                                      field="description"
                                      [suggestions]="filteredTranTypes"
                                      (completeMethod)="searchTranType($event)"
                                      [autoHighlight]="true"
                                      [dropdown]="true"
                                      minLength="2"
                                      forceSelection=true
                                      [pTooltip]="rowData.tranType?.description"
                                      tooltipPosition="bottom"
                                      required=true>
                        <ng-template let-option pTemplate="item">
                          <div>{{option.label}}</div>
                        </ng-template>
                      </p-autoComplete>
                    </td>
                    <td>
                      <p-autoComplete [inputStyle]="{'width':'85%','padding':'0'}"
                                      [(ngModel)]="rowData.taxType"
                                      field="code"
                                      [suggestions]="filteredTaxTypes"
                                      (completeMethod)="searchTaxTypeCode($event)"
                                      [autoHighlight]="true"
                                      [dropdown]="true"
                                      minLength="2"
                                      forceSelection=true
                                      [pTooltip]="rowData.taxType?.description"
                                      tooltipPosition="bottom"
                                      required=true>
                        <ng-template let-option pTemplate="item">
                          <div>{{option.label}}</div>
                        </ng-template>
                      </p-autoComplete>
                    </td>
                    <td>
                      <input style="width: 100%;padding: 0" type="number" pInputText
                             [(ngModel)]="rowData.localAmount" (ngModelChange)="rowData.localTax=calculateLocalTax(rowData.taxType, rowData.localAmount)"
                      />
                    </td>
                    <td>
                      <input style="width: 100%;padding: 0" type="number" pInputText [(ngModel)]="rowData.localTax"/>
                    </td>
                    <td>
                      <input style="width: 100%;padding: 0" type="number" pInputText
                             [(ngModel)]="rowData.foreignAmount"/>
                    </td>
                    <td>
                      <input style="width: 100%;padding: 0" type="number" pInputText [(ngModel)]="rowData.foreignTax"/>
                    </td>
                    <td>
                      <p-autoComplete [inputStyle]="{'width':'85%','padding':'0'}"
                                      class="ui-fluid"
                                      [(ngModel)]="rowData.invoiceSection"
                                      field="description"
                                      [suggestions]="filteredInvoiceSections"
                                      (completeMethod)="searchInvoiceSection($event)"
                                      [dropdown]="true"
                                      [autoHighlight]="true"
                                      minLength="2"
                                      forceSelection=true
                                      [pTooltip]="rowData.invoiceSection?.description"
                                      tooltipPosition="bottom"
                                      required=true>
                        <ng-template let-option pTemplate="item">
                          <div>{{option.description}}</div>
                        </ng-template>
                      </p-autoComplete>
                    </td>
                    <td>
                      <p-autoComplete [inputStyle]="{'width':'85%','padding':'0'}"
                                      class="ui-fluid"
                                      [(ngModel)]="rowData.invoiceSubSection"
                                      [suggestions]="filteredInvoiceSubSections"
                                      (completeMethod)="searchInvoiceSubSection($event.query, rowData.invoiceSection)"
                                      [autoHighlight]="true"
                                      [dropdown]="true"
                                      [pTooltip]="rowData.invoiceSubSection"
                                      tooltipPosition="bottom"
                                      minLength="2"
                                      required=true>
                        <ng-template let-option pTemplate="item">
                          <div>{{option}}</div>
                        </ng-template>
                      </p-autoComplete>
                    </td>
                    <td style="text-align:center">
                      <button *ngIf="editingLine!==ri" pButton type="button" icon="pi pi-trash" class="ui-button-info"
                              (click)="editingLine=ri"></button>
                      <button *ngIf="editingLine===ri" pButton type="button" icon="pi pi-check"
                              class="ui-button-success"
                              style="margin-right: .5em" (click)="deleteLine(ri)"></button>
                      <button *ngIf="editingLine===ri" pButton type="button" icon="pi pi-times" class="ui-button-danger"
                              (click)="editingLine=-1"></button>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </ng-container>
            <ng-container *ngIf="showPrintView || invoice.confirmed">
              <div *ngFor="let lineDetail of getLines()"
                   class="ui-g-12 ui-md-12 border-bottom no-padding-top-bottom">
                <div class="ui-g-12 ui-md-12 no-padding-top-bottom"
                     style="font-weight: bold">{{lineDetail.invoiceSection}}</div>
                <div *ngFor="let line of lineDetail.lines" class="ui-g-12 ui-md-12 no-padding-top-bottom">
                  <div class="ui-g-3 ui-md-3 no-padding-top-bottom">
                    <div *ngIf="line.invoiceSubSection" class="ui-g-10 ui-g-offset-2 no-padding-top-bottom">
                      {{line.invoiceSubSection}}
                    </div>
                  </div>
                  <div class="ui-g-3 ui-md-3"></div>
                  <div class="ui-g-2 ui-md-2 no-padding-top-bottom">{{getRounded(line.localTax)}}</div>
                  <div class="ui-g-2 ui-md-2 no-padding-top-bottom">{{line.taxType.sarsTaxCode}}</div>
                  <div class="ui-g-2 ui-md-2 no-padding-top-bottom">{{getRounded(line.localAmount)}}</div>
                </div>
                <div class="ui-g-12 ui-md-12 no-padding-top-bottom">
                  <div class="ui-g-3 ui-md-3"></div>
                  <div class="ui-g-3 ui-md-3 no-padding-top-bottom" style="font-weight: bold">
                    Total {{lineDetail.invoiceSection}}
                  </div>
                  <div class="ui-g-2 ui-md-2" style="font-weight: bold">{{getTotal(lineDetail.lines, 'vat')}}</div>
                  <div class="ui-g-2 ui-md-2"></div>
                  <div class="ui-g-2 ui-md-2"
                       style="font-weight: bold">{{getTotal(lineDetail.lines, 'localAmount')}}</div>
                </div>
              </div>
            </ng-container>
            <div class="ui-g-12 ui-md-12 no-padding-top-bottom">
              <div class="ui-g-3 ui-md-3">
              </div>
              <div class="ui-g-3 ui-md-3">
              </div>
              <div class="ui-g-2 ui-md-2" style="font-weight: bold">Total (Excl. VAT)</div>
              <div class="ui-g-2 ui-md-2"></div>
              <div class="ui-g-2 ui-md-2" style="font-weight: bold">{{getTotal(null, 'totalExclVat')}}</div>
            </div>
            <div class="ui-g-12 ui-md-12 no-padding-top-bottom">
              <div class="ui-g-6 ui-md-6">
              </div>
              <div class="ui-g-2 ui-md-2" style="font-weight: bold">Total VAT</div>
              <div class="ui-g-2 ui-md-2"></div>
              <div class="ui-g-2 ui-md-2" style="font-weight: bold">{{getTotal(null, 'totalVat')}}</div>
            </div>
            <div class="ui-g-12 ui-md-12 no-padding-top-bottom">
              <div class="ui-g-6 ui-md-6"></div>
              <div class="ui-g-6 ui-md-6 border-thin no-padding-top-bottom">
                <div class="ui-g-8 ui-md-8 " style="font-weight: bold">INVOICE TOTAL: ZAR</div>
                <div class="ui-g-4 ui-md-4" style="font-weight: bold">
                  {{ invoice.capturedAmount ? getRounded(invoice.capturedAmount) : "" }}
                </div>
              </div>
            </div>
            <div class="ui-g-6 ui-md-6 ui-fluid">
              <span
                *ngIf="showPrintView || invoice.confirmed"><u><b>Additional Information</b></u><br>{{invoice.additionalInfo}}</span>
              <div *ngIf="!showPrintView && !invoice.confirmed" class="ui-inputgroup">
                        <span class="md-inputfield">
                          <input type="text" pInputText
                                 [(ngModel)]="invoice.additionalInfo">
                          <label>Additional Information</label>
                        </span>
              </div>
            </div>
            <div class="ui-md-12 ui-g-12">
              <div class="ui-g-12 ui-md-12 border-thick">
                <div class="ui-g-12 ui-md-12 no-padding-top-bottom">
                  <div class="ui-g-12 ui-md-12"><strong>OUR BANKING DETAILS</strong></div>
                  <div class="ui-g-4 ui-md-4" *ngFor="let detail of bankingDetails">
                    <div class="ui-g-12 ui-md-12 ui-g-nopad">BANK: {{detail.bank}}</div>
                    <div class="ui-g-12 ui-md-12 ui-g-nopad">BRANCH: {{detail.branchCode}}</div>
                    <div class="ui-g-12 ui-md-12 ui-g-nopad">ACCOUNT NUMBER: {{detail.accountNo}}</div>
                    <div class="ui-g-12 ui-md-12 ui-g-nopad">SWIFT: {{detail.swiftCode}}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="ui-g-12 ui-md-12"></div>
            <div class="ui-g-12 ui-md-12 ui-g-nopad border-thick">
              <div class="ui-g-3 ui-md-3"><b>TAX SUMMARY</b></div>
              <div class="ui-g-9 ui-md-9 ui-g-nopad">
                <div *ngFor="let taxType of getTaxSummary() | keyvalue: order" class="ui-g-3 ui-md-3">
                  <b>{{taxType.key}}=</b> {{getRounded(taxType.value)}}
                </div>
              </div>
            </div>
            <div class="ui-g-12 ui-md-12">
              <b>All business is transacted in terms of our Standard Trading Terms (Copy available on request).
                Interest may charged at maximum permissible on all overdue amounts.</b>
            </div>
            <div class="ui-g-9 ui-md-9">
              <p-table [value]="[{}]" [rows]="2">
                <ng-template pTemplate="header">
                  <tr>
                    <th>Customs Value</th>
                    <th>Gross Weight</th>
                    <th>Volume</th>
                    <th>No. of Pkgs</th>
                    <th>No. of Containers</th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body">
                  <tr>
                    <td>{{selectedBillOfEntry?.totalCustomsValue}}</td>
                    <td>{{file?.transportDocument?.measures?.grossWeight}}
                    <td>{{file?.transportDocument?.measures?.volume}}</td>
                    <td>{{file?.transportDocument?.measures?.numberOfPackages}}</td>
                    <td>{{file?.transportDocument?.measures?.noOfContainers}}</td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
            <div class="ui-g-3 ui-md-3">
              <div class="ui-g-12 ui-md-12">
                <span
                  *ngIf="showPrintView || invoice.confirmed">Channel Controller: {{invoice.channelController}}</span>
                <div *ngIf="!showPrintView && !invoice.confirmed" class="ui-g-12 ui-md-12">
                  <div class="ui-inputgroup">
                        <span class="md-inputfield">
                          <input type="text" pInputText
                                 [(ngModel)]="invoice.channelController">
                          <label>Channel Controller</label>
                        </span>
                  </div>
                </div>
              </div>
              <div class="ui-g-12 ui-md-12">
                <span *ngIf="showPrintView || invoice.confirmed">Invoice Clerk: {{invoice.invoiceClerk}}</span>
                <div *ngIf="!showPrintView && !invoice.confirmed" class="ui-g-12 ui-md-12">
                  <div class="ui-inputgroup">
                        <span class="md-inputfield">
                          <input type="text" pInputText
                                 [(ngModel)]="invoice.invoiceClerk">
                          <label>Invoice Clerk</label>
                        </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="ui-g-12 ui-md-12">
          <p-toolbar class="toolbar">
            <div class="ui-toolbar-group-right">
              <button *ngIf="invoice.confirmed" pButton type="button" label="Print"
                      icon="fa fa-print"
                      (click)="print()"></button>
              <button *ngIf="!invoice.confirmed" pButton type="button"
                      [label]="showPrintView?'Edit':'Print Preview'"
                      [icon]="showPrintView?'fa fa-pencil':'fa fa-print'"
                      (click)="showPrintView=!showPrintView"></button>
              <button pButton type="button" label="Confirm" icon="fa fa-check" (click)="confirmInvoice()"
                      [disabled]="invoice.confirmed || (invoice.localAmount - (getCaptured()) < 0 || getCaptured()===0)">
              </button>
              <button pButton type="button" label="Save" icon="fa fa-save" (click)="saveInvoice()"
                      [disabled]="invoice.confirmed || getCaptured()===0"></button>
              <button pButton type="button" label="New" icon="fa fa-file"
                      (click)="createNewInvoice();showPrintView=false"></button>
            </div>
          </p-toolbar>
        </div>
      </div>
    </ng-template>
  </p-tabPanel>
  <p-tabPanel header="Credit Note">
    <ng-template pTemplate="content">
      <digi-credit-note [invoice]="invoice"
                        [invoices]="invoices"
                        [fileType]="fileType"
                        [logo]="logo"
                        [transportMethod]="transportMethod"
                        [selectedBillOfEntry]="selectedBillOfEntry"
                        [vat]="vat"
                        [bankingDetails]="bankingDetails"
                        [file]="file"></digi-credit-note>
    </ng-template>
  </p-tabPanel>
</p-tabView>
