import {TaxType} from './TariffCode';
import {Currency, CustomOffice} from './file';
import * as moment from 'moment';
import {ClearingFile} from './clearingFile';
import {ExportFile} from './exportFile';


export interface ChargeType {
  code?: string;
  description?: string;
  costingOnly?: boolean;
  allowSundry?: boolean;
  externalChargeTypeCode?: string;
  label?: string;
}

export interface TranType {
  code?: string;
  description?: string;
  isTax?: boolean;
  recoverableInd?: string;
  incomeInd?: string;
  multiplier?: number;
  label?: string;
}

export interface Department {
  code?: string;
  glMasterPrefix?: number;
  description?: string;
  label?: string;
}

export interface InvoiceSection {
  id?: number;
  description?: string;
  sortSequence?: string;
  companyCode?: string;
}

export interface InvoiceSubSection {
  description?: string;
}

export class SupplierInvoiceLine {
  chargeType?: ChargeType;
  tranType?: TranType;
  department?: Department;
  taxType: TaxType;
  foreignAmount?: number;
  foreignTax?: number;
  localAmount?: number;
  localTax?: number;
  invoiceSection?: InvoiceSection;
  invoiceSubSection?: string;
  forAirImports?: boolean;
  forSeaImports?: boolean;
  forRoadImports?: boolean;
  forAirExports?: boolean;
  forSeaExports?: boolean;
  forRoadExports?: boolean;
}

export class CreditorFinancialDetail {
  creditorNumber?: string;
  extCreditorCode?: string;
  customOffice?: CustomOffice;
  documentNumber?: string;
  paymentReference?: string;
  documentDate?: Date;
  financialDate?: Date;
  totalInvoiceValue?: number;
  homeValue?: number;
  currency?: Currency;
  confirmDate?: Date;
  confirmed?: boolean;
  userCode?: string;
  defaultTaxType: TaxType;
  documentStatus?: string;
  balanceTotalValue?: number;
  balanceCapturedValue?: number;
  balanceCurrentValue?: number;
  creditorChargeTypeDetails?: CreditorChargeTypeDetails[];
}

export class CreditorChargeTypeDetails {
  fileNumber?: string;
  chargeType: ChargeType;
  tranType: TranType;
  taxType: TaxType;
  sourceAmount: number;
  sourceTax: number;
  localAmount: number;
  localTax: number;
}

export class CsvExportInvoice {
  chargeType?: string;
  vatAmount?: number;
  vatCode?: string;
  invoiceAmount?: number;
  invoiceDate?: string;
  customerCode?: string;
  orderNumber?: string;
  documentNumber?: string;
}

export class InvoiceTransaction {
  transactionDate?: string;
  transactionType?: string;
  journalReference?: string;
  journalDescription?: string;
  transactionAmount?: number;
}

export class InvoiceTransactionDetails {
  transactionDate?: string;
  statementDescription?: string;
  cashBookAccountNo?: string;
  zarAmount?: number;
  currencyCode?: string;
  referenceNo?: string;
  roeForUAValues?: number;
}

export class Invoice {
  invoiceNo: string;
  companyVatNo: string;
  companyReg: string;
  companyName: string;
  companyPhysicalAddress?: string;
  companyPostalAddress?: string;
  companyTel?: string;
  companyFax?: string;
  companyEmail?: string;
  companyBank: string;
  companyBankBranch: string;
  companyBankAccNo: string;
  companySwift: string;
  invoiceDate: string;
  reference: string;
  altReference: string;
  lineDetails: SupplierInvoiceLine[];
  transactions: InvoiceTransaction[];
  transactionDetails: InvoiceTransactionDetails;
  clearingFile?: ClearingFile;
  exportFile?: ExportFile;
  exBondFile?: ExportFile;
  currency: Currency;
  exchangeRate: number;
  invoiceDueDate: string;
  additionalInfo: string;
  channelController: string;
  invoiceClerk: string;
  taxType: TaxType;
  balance: number;
  localAmount: number;
  capturedAmount: number;
  foreignAmount: number;
  paidAmount?: number;
  confirmed: boolean;
  type: string;
  message?: string;
  copy?: boolean;
  debtorCodeRef?: string;
  fileReferenceNumber?: string;
  [k: string]: any;

  constructor () {
    this.localAmount = 0;
    this.invoiceDate = moment().format('DD/MM/YYYY');
  }
}

export class CreditNoteReason {
  code?: string;
  description?: string;
}

export class CreditNote extends Invoice {
  reason?: CreditNoteReason;
  creditNoteNo?: string;
  creditNoteDate?: string;
}
