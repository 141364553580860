import { OnInit, OnDestroy } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import * as moment from 'moment';
import { BusinessEntity, Debtor, Country, Currency } from 'src/app/digi-business-entity/models/business-entity';
import { InvoiceTransactionDetails, InvoiceTransaction } from 'src/app/digi-common/models/financials';
import { CompanyService } from '../../services/company.service';
import { NotificationService } from '../../services/notification.service';
import { BusinessEntityService } from '../../../digi-business-entity/services/business-entity.service';
import { PaymentsReportService } from '../../services/payments-report.service';
import { InvoiceService } from '../../services/invoice.service';
var DebtorsTransactionsComponent = /** @class */ (function () {
    function DebtorsTransactionsComponent(businessEntityService, messageService, confirmationService, invoiceService, paymentsReportService, companyService) {
        this.businessEntityService = businessEntityService;
        this.messageService = messageService;
        this.confirmationService = confirmationService;
        this.invoiceService = invoiceService;
        this.paymentsReportService = paymentsReportService;
        this.companyService = companyService;
        this.filteredDebtorEntities = [];
        this.shouldUpdateDebtorCreditBalance = false;
        this.activeIndex = 0;
    }
    DebtorsTransactionsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.cols = [
            { header: 'Invoice Date' },
            { header: 'File Ref.' },
            { header: 'Invoice No.' },
            { header: 'Invoice Amount' },
            { header: 'O/S Amount' }
        ];
        this.transactionCols = [
            { header: 'Transaction Date' },
            { header: 'Transaction Type' },
            { header: 'Amount' }
        ];
        this.transactionTypes = [
            { 'label': '', 'value': '' }, { 'label': 'PAYMENT', 'value': 'PAYMENT' }, { 'label': 'REVERSAL', 'value': 'REVERSAL' }, { 'label': 'CR JOURNAL', 'value': 'CR JOURNAL' }, { 'label': 'DR JOURNAL', 'value': 'DR JOURNAL' }
        ];
        this.invoicesSelection = [];
        this.transactionsSelection = [];
        this.clearDebtor();
        this.companyService.getCompany().subscribe(function (companies) {
            if (companies && companies.length) {
                var branch = companies[0].branches[0];
                if (branch.creditLimitActive) {
                    _this.shouldUpdateDebtorCreditBalance = true;
                }
            }
        });
    };
    DebtorsTransactionsComponent.prototype.search = function (event) {
        var _this = this;
        this.businessEntityService.findBusinessEntityStartsWithAndRoleType(event.query, 1).subscribe(function (data) {
            _this.filteredDebtorEntities = data;
        });
    };
    DebtorsTransactionsComponent.prototype.saveTransactions = function () {
        var _this = this;
        this.invoicesSubscription = this.invoiceService.saveInvoiceTransactions(this.selectedInvoice).subscribe(function (data) {
            _this.messageService.successNotify('Transaction Saved Successfully');
        });
    };
    DebtorsTransactionsComponent.prototype.clearDebtor = function () {
        this.debtorParentEntity = null;
        this.debtorEntity = new Debtor();
        this.invoicesSelection = [];
        this.transactionsSelection = [];
    };
    DebtorsTransactionsComponent.prototype.getInvoiceBalance = function (invoice) {
        if (!invoice.paidAmount) {
            return invoice.localAmount;
        }
        else {
            return Number(invoice.localAmount - invoice.paidAmount).toFixed(2);
        }
    };
    DebtorsTransactionsComponent.prototype.handleIndexChange = function (event) {
        if (event.index === 1) {
            this.activeIndex = 1;
        }
        else if (event.index === 0) {
            this.activeIndex = 0;
        }
        this.pdfSrc = null;
    };
    DebtorsTransactionsComponent.prototype.addNewTransaction = function () {
        var _this = this;
        var validation = this.validate();
        if (validation.isValid) {
            this.confirmationService.confirm({
                message: 'Are you sure that you want to add this transaction to invoice: ' + this.selectedInvoice.invoiceNo + '?',
                accept: function () {
                    var transaction = new InvoiceTransaction();
                    transaction.transactionType = _this.tranTypeToAdd;
                    transaction.transactionAmount = _this.tranAmountToAdd;
                    transaction.transactionDate = moment().format('DD/MM/YYYY');
                    if (_this.tranTypeToAdd === 'CR JOURNAL' || _this.tranTypeToAdd === 'DR JOURNAL') {
                        transaction.journalReference = _this.journalReferenceToAdd;
                        transaction.journalDescription = _this.journalDescriptionToAdd;
                    }
                    if (_this.transactionsSelection && _this.transactionsSelection.length > 0) {
                        _this.transactionsSelection.push(transaction);
                    }
                    else {
                        _this.transactionsSelection = [transaction];
                    }
                    if (_this.tranTypeToAdd === 'PAYMENT' || _this.tranTypeToAdd === 'CR JOURNAL') {
                        _this.selectedInvoice.paidAmount = _this.selectedInvoice.paidAmount + Number(_this.tranAmountToAdd);
                        if (_this.shouldUpdateDebtorCreditBalance) {
                            var newBalance = Number(_this.debtorEntity.creditBalance) + Number(_this.tranAmountToAdd);
                            _this.debtorEntity.creditBalance = String(newBalance);
                        }
                    }
                    else {
                        _this.selectedInvoice.paidAmount = _this.selectedInvoice.paidAmount - Number(_this.tranAmountToAdd);
                        if (_this.shouldUpdateDebtorCreditBalance) {
                            var newBalance = Number(_this.debtorEntity.creditBalance) - Number(_this.tranAmountToAdd);
                            _this.debtorEntity.creditBalance = String(newBalance);
                        }
                    }
                    _this.selectedInvoice.transactionDetails.zarAmount = _this.getInvoiceBalance(_this.selectedInvoice);
                    _this.selectedInvoice.transactions = _this.transactionsSelection;
                    var indexToUpdate = _this.debtorParentEntity.roles.findIndex(function (role) { return role.roleType === 1; });
                    _this.debtorParentEntity.roles[indexToUpdate] = _this.debtorEntity;
                    _this.businessEntityService.saveBusinessEntity(_this.debtorParentEntity)
                        .subscribe(function (data) {
                        _this.onSelectDebtorEntity(data);
                        _this.messageService.successNotify('Debtors Credit Balance Updated');
                    }, function (error) {
                    });
                    _this.saveTransactions();
                    _this.clearTransaction();
                }
            });
        }
        else {
            this.addFailure(validation.messages);
            return;
        }
    };
    DebtorsTransactionsComponent.prototype.clearTransaction = function () {
        this.tranTypeToAdd = '';
        this.tranAmountToAdd = null;
        this.journalReferenceToAdd = null;
        this.journalDescriptionToAdd = null;
    };
    DebtorsTransactionsComponent.prototype.selectInvoice = function (invoice) {
        this.selectedInvoice = invoice;
        if (!this.selectedInvoice.transactionDetails) {
            var transactionDetails = new InvoiceTransactionDetails();
            transactionDetails.zarAmount = this.getInvoiceBalance(this.selectedInvoice);
            transactionDetails.currencyCode = this.selectedInvoice.currency.code;
            this.selectedInvoice.transactionDetails = transactionDetails;
        }
        if (this.selectedInvoice.transactions) {
            this.transactionsSelection = this.selectedInvoice.transactions;
        }
    };
    DebtorsTransactionsComponent.prototype.hasValidDetails = function () {
        return this.tranTypeToAdd && this.tranTypeToAdd !== '' && this.tranAmountToAdd && this.tranAmountToAdd > 0;
    };
    DebtorsTransactionsComponent.prototype.selectTransaction = function (value) {
    };
    DebtorsTransactionsComponent.prototype.onSelectDebtorEntity = function (businessEntity) {
        var _this = this;
        this.clearTransaction();
        this.invoicesSelection = [];
        this.transactionsSelection = [];
        this.debtorParentEntity = businessEntity;
        this.debtorEntity = this.debtorParentEntity.roles.find(function (role) { return role.roleType === 1; });
        this.invoicesSubscription = this.invoiceService.findInvoicesByDebtorCode(this.debtorParentEntity.code).subscribe(function (invoices) {
            if (invoices) {
                if (invoices.importInvoices) {
                    (_a = _this.invoicesSelection).push.apply(_a, invoices.importInvoices);
                }
                if (invoices.exportInvoices) {
                    (_b = _this.invoicesSelection).push.apply(_b, invoices.exportInvoices);
                }
                if (invoices.exbondInvoices) {
                    (_c = _this.invoicesSelection).push.apply(_c, invoices.exbondInvoices);
                }
                _this.selectInvoice(_this.invoicesSelection[0]);
            }
            else {
                _this.messageService.errorNotify('Warning', ['No invoices found']);
            }
            var _a, _b, _c;
        });
    };
    DebtorsTransactionsComponent.prototype.selectCurrency = function (value) {
        this.debtorEntity.currency = value;
    };
    DebtorsTransactionsComponent.prototype.selectCountry = function (value) {
        this.debtorEntity.country = value;
    };
    DebtorsTransactionsComponent.prototype.validate = function () {
        var validation = {
            isValid: true,
            messages: []
        };
        if ((this.tranTypeToAdd === 'REVERSAL' || this.tranTypeToAdd === 'DR JOURNAL') && Number(this.tranAmountToAdd) > this.getInvoiceBalance(this.selectedInvoice)) {
            validation.isValid = false;
            validation.messages.push('Cannot process transaction - reversal or debit journal amounts cannot exceed the outstanding balance');
            return validation;
        }
        if ((this.tranTypeToAdd === 'CR JOURNAL' || this.tranTypeToAdd === 'DR JOURNAL') && (!this.journalDescriptionToAdd || !this.journalReferenceToAdd)) {
            validation.isValid = false;
            validation.messages.push('Cannot process journal - please provide  reference and description');
            return validation;
        }
        return validation;
    };
    DebtorsTransactionsComponent.prototype.generate = function () {
        var _this = this;
        this.disableButton = true;
        this.reportSubscription = this.paymentsReportService.generateDebtorsStatementReports(this.fromDate ? this.fromDate : null, this.toDate ? this.toDate : null, this.debtorParentEntity && this.debtorParentEntity.code ? this.debtorParentEntity.code : null, this.debtorParentEntity && this.debtorEntity.currency ? this.debtorEntity.currency.code.trim() : 'ZAR').subscribe(function (data) {
            _this.pdfSrc = data._embedded.supportingDocuments[0]._links.self.href + ("?date=" + Date.now());
        }, function (error) {
            _this.messageService.errorNotify(error.error.message, error.error.errors);
            _this.pdfSrc = null;
            _this.disableButton = false;
        });
    };
    DebtorsTransactionsComponent.prototype.openLink = function () {
        window.open(this.pdfSrc, '_blank');
    };
    DebtorsTransactionsComponent.prototype.addFailure = function (msg) {
        this.messageService.errorNotify('Warning', msg);
    };
    DebtorsTransactionsComponent.prototype.ngOnDestroy = function () {
        if (this.invoicesSubscription) {
            this.invoicesSubscription.unsubscribe();
        }
        if (this.reportSubscription) {
            this.reportSubscription.unsubscribe();
        }
    };
    return DebtorsTransactionsComponent;
}());
export { DebtorsTransactionsComponent };
